import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>
<div class="message">No differences</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}